/**
 * Do not remove any keys!!
 * The shape of this file is to remain the same so that all options are know.
 *
 * If a key does not have a value, set the value to undefined.
 */
module.exports = {
  communityName: "Entrata Farms",
  gtagId: "G-X4VXVX4SN0",
  logo: {
    src: "https://cdn.maxxpm.com/entratafarms/logosandbanners/long_logo.png",
    alt: "Entrata Farms Logo",
  },
  nav: {
    bannerSubtitle: undefined,
    bannerTitle: undefined,
    bannerCTA: undefined,
  },
  footer: {
    address: "3968 W Perugia St.",
    cityStateZip: "Meridian, ID 83642",
    phone: "(208) 219-5940",
    copyrightYear: "2024",
    logo: "https://cdn.maxxpm.com/entratafarms/logosandbanners/long_white.png",
  },
  listMarkerSrc: "https://cdn.maxxpm.com/entratafarms/logosandbanners/li_marker.png",
  hasCalendlyPopup: false,
  calendlyLink: undefined,
  home: {
    banner: {
      image: "https://cdn.maxxpm.com/entratafarms/3storypayton/4E0A4182-HDR_3.jpg",
      video: undefined,
      title: "Stylish Upgraded Townhomes",
      subtitle: "Welcome Home!",
    },
    aboutUs: {
      paragraphs: [
        "Entrata Farms is a premier townhome community located in Meridian, just minutes away from I-84. This townhome community is walking distance to a variety of banks, first-rate entertainment, world-class outdoor activities, and delicious restaurants. Residents also enjoy many onsite amenities including a large playground, dog park, expansive recreational green space, well-kept walking paths, and more!",
        "With 2 unique townhome layouts to offer, Entrata Farms is bound to have something that fits your lifestyle. Our townhomes offer gorgeous open-concept layouts and state-of-the-art appliances at an affordable price. We can't wait to welcome you home!",
      ],
      image: {
        src: "https://cdn.maxxpm.com/entratafarms/3storypayton/entrata_22_1.jpg",
        alt: "Entrata Farms Pool",
      },
    },
    floorplanTitle: "Explore Our Floor Plans and Take a 3D Virtual Tour",
    floorplans: [
      {
        hasImage: true,
        image: {
          src: "https://cdn.maxxpm.com/entratafarms/logosandbanners/Payton.jpg",
          alt: "3 Story Townhome Floor Plan",
        },
        hasWalkthrough: true,
        walkthrough: {
          src: "https://my.matterport.com/show/?m=yftUZvDJBgF",
          title: "Payton model 3d walkthrough",
        },
      },
      {
        hasImage: true,
        image: {
          src: "https://cdn.maxxpm.com/entratafarms/logosandbanners/Tucker.jpg",
          alt: "2 Story Townhome Floor Plan",
        },
        hasWalkthrough: true,
        walkthrough: {
          src: "https://my.matterport.com/show/?m=cVi1NQoL4gQ",
          title: "Tucker model 3d walkthrough",
        },
      },
    ],
    midContainerImage: "https://cdn.maxxpm.com/entratafarms/3storypayton/entrata_11_1.jpg",
    imgRow: [
      {
        src: "https://cdn.maxxpm.com/entratafarms/3storypayton/entrata_1_1.jpg",
        alt: "Entrata Farms Playground",
      },
      {
        src: "https://cdn.maxxpm.com/entratafarms/3storypayton/entrata_5_1.jpg",
        alt: "Entrata Farms exterior view",
      },
      {
        src: "https://cdn.maxxpm.com/entratafarms/3storypayton/entrata_12_1.jpg",
        alt: "Entrata Farms dog park",
      },
    ],
    animalPolicy: {
      img: {
        src: "https://cdn.maxxpm.com/blackstonefarms/logoandbanners/S 30.jpg",
        alt: "Animal policy stock photo",
      },
      policies: [
        "No more than 2 animals are allowed per property",
        "Cats/Dogs: $250/animal*",
        "$50/animal/month for animals under 50lbs*",
        "$75/animal/month for animals 50lbs+*",
        "One-time $75/animal DNA testing fee*",
        "Caged animals: $25/animal/month*",
        "Fish tanks are not permitted",
        "Rabbits and rodents of any kind are not permitted",
      ],
      breedRestrictions: [
        "Pit Bulls & Staffordshire Terriers",
        "Doberman Pinschers",
        "Rottweilers",
        "Chows",
        "Great Danes",
        "Presa Canarios/ Mastiffs",
        "Akitas",
        "Alaskan Malamutes",
        "Huskies",
        "German Shepherds",
        "Shar Peis",
        "Wolf-Hybrids",
      ],
    },
  },
  availability: {
    banner: {
      image: "https://cdn.maxxpm.com/entratafarms/3storypayton/4E0A4182-HDR_3.jpg",
      video: undefined,
      title: "Available Units",
      subtitle:
        "Unit availability is updated often from Monday-Friday during business hours. Evenings and weekends may not reflect accurate availability dates.",
    },
    hasWaitingList: false,
    waitingListJotformId: undefined,
    propertyGroup: "LEADS-EF",
  },
  faq: {
    banner: {
      image: "https://cdn.maxxpm.com/dublinfarms/logosandbanners/Eagle Mountain.jpg",
    },
    dnaRequired: true,
  },
};
